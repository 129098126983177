// I have followed this: https://coreui.io/docs/getting-started/theming/ in order to customize the theme according the VIWONE SAV theme
// Here you can add other styles
// Import styles

// @import "~@coreui/coreui/scss/coreui.scss";

// With that setup in place, you can begin to modify any of the Sass variables and maps in your custom.scss.

// Variable defaults
// Every Sass variable in CoreUI includes the !default flag allowing you to override the variable’s default value in your own Sass without modifying CoreUI’s source code.

// If a variable has already been assigned, then it won’t be re-assigned by the default values in CoreUI.
// You will find the complete list of CoreUI’s variables in scss/_variables.scss.
// Some variables are set to null, these variables don’t output the property unless they are overridden in your configuration.

// Variable overrides within the same Sass file can come before or after the default variables.

// However, when overriding across Sass files, your overrides must come before you import CoreUI’s Sass files.

// Your variable overrides
$body-bg: rgba(0, 255, 42, 0);
// $body-color: #111;
$theme-colors: (
  "primary": #b89f6e,
  "success": #b89f6e,
  "dark": #000000,
  "pure-black-mat": #eb6383,
  "pure-gold-mat": #a8df65,
  "pure-plus-black-mat": #0a97b0,
  "pure-plus-gold-mat": #ff9a76,
  "limited-gold-chrome": #ffcbcb,
  "player-black-mat": #2fc4b2,
  "player-gold-mat": #fcf876,
  "one-white-mat": #63b7af,
  // This serves as the color of pure plus white
    "gamme-one-eternal-black": #2000ad,
  "gamme-one-eternal-gold": #cc069a,
  "gamme-one-rebel-chrome": #00e1ff,
);

// Overriding Sidebar hover color
// $sidebar-nav-link-hover-color: #ff36cd;
$sidebar-nav-link-hover-bg: #b89f6e;
$sidebar-nav-link-icon-color: #b89f6e;
// $sidebar-nav-link-disabled-icon-color: #b89f6e;
$sidebar-nav-link-active-icon-color: #b89f6e;

// These variable names are from the file _sidebar.scss in the original coreui template
// For some reason, I didn't find it in this project even though it's a coreui template
// In order to learn the name of the variables, you should modify, download the coreui template and check the files inside the scss folder
// And choose which variables you'd like to modify

// Every Sass variable in CoreUI includes the !default flag allowing you to override the variable’s default value in your own Sass without modifying CoreUI’s source code.
$header-light-color: #ff36cd;
// Customizing Pagination color
.pagination > li > a {
  background-color: white;
  color: #5a4181;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  color: #5a5a5a;
  background-color: #eee;
  border-color: #ddd;
}

.pagination > .active > a {
  color: white;
  background-color: #b89f6e !important;
  border: solid 1px #ddd !important;
}

.pagination > .active > a:hover {
  background-color: #bd9036 !important;
  border: solid 1px #ddd;
}

// CoreUI and its default variables
@import "~@coreui/coreui/scss/coreui.scss";
